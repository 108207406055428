<template>
    <validation-provider
        ref="provider"
        v-slot="{ errors, validate, changed, validated }"
        slim
        :detect-input="false"
        :rules="rules"
        :name="fieldName"
        :vid="$attrs.vid"
    >
        <div ref="wrapper" :class="rootClass">
            <div class="v-input__control">
                <div class="v-input__slot">
                    <div class="v-text-field__slot">
                        <label :class="labelClass" :for="`${id}`">
                            {{ labelAltered }}
                        </label>
                        <vue-tel-input
                            ref="phoneInput"
                            v-bind="attrs"
                            mode="international"
                            valid-characters-only
                            v-on="getListeners(validate, changed, validated)"
                        />
                    </div>
                </div>
                <v-progress-linear v-if="loading" height="2" indeterminate />
                <div class="v-text-field__details">
                    <v-slide-y-transition>
                        <div
                            v-if="errors.length"
                            class="v-messages theme--light error--text"
                            role="alert"
                        >
                            <div class="v-messages__wrapper">
                                <div class="v-messages__message">
                                    {{ errors[0] }}
                                </div>
                            </div>
                        </div>
                    </v-slide-y-transition>
                </div>
            </div>
        </div>
    </validation-provider>
</template>

<script lang="ts">
import Vue from 'vue';
import Component from 'vue-class-component';

import { ValidationProvider } from 'vee-validate';
import { VueTelInput, VueTelInputMethods } from 'vue-tel-input';

import { parsePhoneNumberFromString } from 'libphonenumber-js';

import {
    setValidator,
    unsetValidator
} from '@/plugins/vee-validate/rules/runtime';

import { Watch } from '@/utils/decorators';
import { wait } from '@/utils/helpers';

type PhoneObject = { valid?: boolean; number: string };

const APhoneInputProps = Vue.extend({
    name: 'APhoneInput',
    props: {
        value: {
            type: String,
            default() {
                return '';
            }
        },
        label: {
            type: String,
            default() {
                return '';
            }
        },
        field: {
            type: String,
            default() {
                return '';
            }
        },
        name: {
            type: String,
            default() {
                return '';
            }
        },
        loading: {
            type: Boolean,
            default() {
                return false;
            }
        },
        disabled: {
            type: Boolean,
            default() {
                return false;
            }
        }
    }
});

@Component({
    inheritAttrs: false,
    components: {
        VueTelInput,
        ValidationProvider
    }
})
export default class APhoneInput extends APhoneInputProps {
    _uid!: number;

    $refs!: {
        provider: InstanceType<typeof ValidationProvider>;
        phoneInput: InstanceType<typeof VueTelInput> & VueTelInputMethods;
        wrapper: HTMLDivElement;
    };

    innerValue = this.value || '';
    isFocused = false;
    isOpen = false;
    isInputValid = true;
    maxWidth = 390;

    isMounted = false;
    isChanged = false;
    isValidated = false;

    get id() {
        return this._uid;
    }

    get fieldName() {
        return this.field || this.label || this.name;
    }

    get labelAltered() {
        return `${this.label}${this.required && !this.disabled ? ' *' : ''}`;
    }

    get isValid() {
        if (this.innerValue || !this.required) {
            return this.isInputValid === true;
        }

        return this.isMounted ? this.$refs.provider.errors.length === 0 : true;
    }

    get rules() {
        return [this.$attrs.rules, `phone:${this.id}`]
            .filter(Boolean)
            .join('|');
    }

    get required() {
        return typeof this.rules === 'string'
            ? this.rules?.includes('required')
            : Object.keys(this.rules).includes('required');
    }

    get rootClass() {
        const classes = [
            'v-input-phone',
            'v-input theme--light',
            'v-text-field',
            'v-text-field--filled',
            'v-text-field--is-booted',
            'v-text-field--enclosed'
        ];

        if (this.isFocused) {
            classes.push('v-input--is-focused', 'primary--text');
        }

        if (this.innerValue) {
            classes.push('v-input--is-dirty');
        }

        if ((this.isChanged || this.isValidated) && !this.isValid) {
            classes.push('v-input--has-state', 'error--text');
        }

        if (this.loading) {
            classes.push('v-input--is-loading');
        }

        return classes.join(' ');
    }

    get labelClass() {
        const classes = new Set(['v-label', 'theme--light']);

        if (this.isFocused || this.isOpen) {
            classes.add('primary--text');
            classes.add('v-label--active');
        }

        if (this.innerValue) {
            classes.add('v-label--active');
        }

        if ((this.isChanged || this.isValidated) && !this.isValid) {
            classes.add('error--text');
        }

        return [...classes].join(' ');
    }

    get attrs() {
        const { disabled } = this.$attrs;

        return {
            defaultCountry: 'US',
            preferredCountries: ['US', 'GB'],
            dropdownOptions: {
                width: `${this.maxWidth}px`,
                showFlags: true,
                showDialCodeInList: true,
                //showDialCodeInSelection: true,
                showSearchBox: true
            },
            inputOptions: {
                placeholder: '',
                id: this.id
            },
            disabled,
            // due to bug in vue-tel-input https://github.com/iamstevendao/vue-tel-input/issues/241
            // we have to explicitly conver falsy value into empty string
            value: this.innerValue || ''
        };
    }

    @Watch('value')
    onValueChange(value: string) {
        if (this.innerValue !== value) {
            this.innerValue = value;

            if (this.isMounted) {
                this.$refs.provider.syncValue(this.innerValue);
            }
        }
    }

    getListeners(
        validate: (v: string) => Promise<{ valid: boolean }>,
        changed: boolean,
        validated: boolean
    ) {
        this.isChanged = changed;
        this.isValidated = validated;

        return {
            focus: () => {
                this.isFocused = true;
            },
            blur: () => {
                this.isFocused = false;

                validate(this.innerValue);
            },
            open: () => {
                this.isOpen = true;
                setTimeout(this.focusSearchInput.bind(this), 50);
            },
            close: () => {
                this.isOpen = false;
            },
            input: (value: string) => {
                value = value.trim();

                const isUserInput = this.isUsersInput(value);

                this.innerValue = value;

                if (this.isMounted && (this.innerValue || this.isChanged)) {
                    validate(this.innerValue);
                }

                if (isUserInput) {
                    this.$refs.provider.setFlags({
                        dirty: true,
                        changed: true,
                        touched: true
                    });

                    this.$emit('input', this.innerValue);
                }
            },
            validate: (data: PhoneObject) => {
                if (typeof data.valid !== 'undefined') {
                    this.validateLatest(data);

                    this.isInputValid = data.valid;
                }
            }
        };
    }

    created() {
        setValidator(this.id, (value: string) => {
            if (!this.isValid) {
                const phone: PhoneObject = {
                    valid: false,
                    number: value
                };

                this.validateLatest(phone);

                return Boolean(phone.valid);
            }

            return this.isValid;
        });
    }

    beforeDestroy() {
        unsetValidator(this.id);
    }

    async mounted() {
        // turn on validation
        this.isMounted = true;
        // inherit the width of the control
        this.maxWidth = (await wait(
            () => this.$refs.wrapper?.clientWidth
        )) as number;
    }

    focusSearchInput() {
        const list = this.$refs.phoneInput.$refs.list as HTMLDivElement;
        const inputs = list.getElementsByClassName('vti__search_box');

        if (inputs.length) {
            (inputs[0] as HTMLInputElement).focus();
        }
    }

    choose(country: string) {
        this.$refs.phoneInput.choose(country);
    }

    isUsersInput(value: string) {
        return (
            (this.innerValue || '').replace(/\D/g, '') !==
            value.replace(/\D/g, '')
        );
    }

    validateLatest(data: PhoneObject) {
        if (!data.valid) {
            const phone = parsePhoneNumberFromString(data.number);

            if (phone) {
                const valid = phone.isValid();

                if (valid) {
                    this.$refs.phoneInput.choose(phone.country);

                    data.valid = true;
                }
            }
        }
    }
}
</script>

<style src="vue-tel-input/dist/vue-tel-input.css"></style>
<style lang="scss">
.v-input-phone {
    &.v-text-field--filled .v-label {
        top: 13px;
        left: 0px;
        right: auto;
        position: absolute;

        &.v-label--active {
            top: 18px;
        }
    }

    &.v-text-field--single-line {
        .vue-tel-input {
            align-items: center;
            .vti__input {
                margin-bottom: 0;
            }
        }
    }

    .vue-tel-input {
        border: none;
        width: calc(100% + 11px);
        margin-left: -11px;
        align-items: end;

        &:focus-within {
            border: none;
            box-shadow: none;
        }

        .vti__input {
            margin-bottom: 2px;
        }

        .vti__dropdown {
            &.open,
            &:hover {
                background-color: transparent;
            }
        }

        .vti__dropdown-list {
            padding: 0;

            .vti__search_box {
                width: calc(100% - 20px);
                margin: 10px;
                padding: 3px 5px;
            }

            .vti__dropdown-item {
                padding: 4px 7px;

                &:focus,
                &:focus-visible {
                    outline: none;
                }

                .vti__flag-wrapper {
                    width: 35px;

                    .vti__flag {
                        margin-right: 10px;
                    }
                }

                strong {
                    font-weight: 500;
                }
            }
        }
    }

    .v-input__control [role='progressbar'] {
        position: relative;
        top: -9px;
        margin-bottom: -2px;
    }
}
</style>
