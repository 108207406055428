<template>
    <v-menu
        transition="slide-y-transition"
        :close-on-click="true"
        :close-on-content-click="true"
        :offset-y="true"
        left
        @input="open"
    >
        <template #activator="{ on, attrs }">
            <v-btn fab plain v-bind="attrs" v-on="on">
                <v-icon>ellipsis-v</v-icon>
            </v-btn>
        </template>
        <v-list>
            <v-list-item key="edit" @click="edit">
                <v-list-item-icon class="mr-2">
                    <v-icon small>edit</v-icon>
                </v-list-item-icon>
                <v-list-item-title>Edit</v-list-item-title>
            </v-list-item>
            <v-list-item key="delete" @click="drop">
                <v-list-item-icon class="mr-2">
                    <v-icon small color="error">trash</v-icon>
                </v-list-item-icon>
                <v-list-item-title class="error--text">
                    Delete
                </v-list-item-title>
            </v-list-item>
        </v-list>
    </v-menu>
</template>

<script lang="ts">
import Vue, { PropType } from 'vue';
import Component from 'vue-class-component';

import type { Company } from '@/types/Company';

const CompanyRowActionsProps = Vue.extend({
    name: 'CompanyRowActions',
    props: {
        company: {
            type: Object as PropType<Partial<Company>>,
            default(): Company | null {
                return null;
            }
        }
    }
});

@Component
export default class CompanyRowActions extends CompanyRowActionsProps {
    open(isOpen: boolean) {
        this.$emit('open', isOpen);
    }

    drop() {
        this.$emit('drop', this.company);
    }

    edit() {
        this.$emit('edit', this.company);
    }
}
</script>
