<template>
    <a-banner
        v-if="!isKamManagedUser"
        class="welcome-banner"
        :class="{ ampcast: hasAiFeatures }"
    >
        <!-- for reference only <span
            v-if="
                isBetween(['2024-07-03 11:00'], ['2024-07-07 11:00']) &&
                !isNewSimplerTrafficBuyer
            "
        >
            Discover Our New Traffic Playbook For 2024
            <a href="https://letsgolook.at/TrafficPlaybook2024" target="_blank">
                <v-icon color="primary" x-small>arrow-right</v-icon>
                RSVP HERE
            </a>
        </span> -->
        <div v-if="hasAiFeatures" class="my-4">
            <b>
                Congrats! You now have access to AmpCast features when creating
                your Amp campaigns.
            </b>
            <br />
            So far this includes (but isn't limited to) Interview-style
            podcasts, RSS feeds, connecting and publishing to your social media
            accounts, embedding a video in Blog module... and more is on the
            way!
            <br />
            And as a Founding Backer you'll get free access to each future
            upgrade as they become available.
            <br />
            So dive in now and launch your first Amp with AmpCast and see what
            it can do!
        </div>

        <div v-if="isAsigoBuyerOnly" class="my-4">
            We are excited to announce that Asigo has been upgraded to DropServe
            with new sales material, new software and new strategies to help you
            maximize your sales and profits.
            <br />
            Review our new training to discover the new platform and take part
            in our latest challenge and to grow your hands-off agency.
            <br />
            Join the sessions
            <a href="/training/dropserve/index" target="_blank">
                <v-icon color="primary" x-small>arrow-right</v-icon>
                here
            </a>
        </div>

        <div v-if="isDropServeBuyer" class="my-4">
            Welcome to the DropServe, your DropService whitelabel platform where
            we will guide you on starting and scaling your own DropService
            business. Start here:
            <a href="https://www.dropserve.ai/thankyou" target="_blank">
                https://www.dropserve.ai/thankyou
            </a>
        </div>

        <!-- Banners For All Users -->
        <div
            v-if="isBetween(['2025-03-27 11:00'], ['2025-03-30 11:00'])"
            class="my-4"
        >
            Join The AI Games! Our 3-Day Challenge To 5X Yourself With A.I. In
            Your Business And Grow ANY Business Faster Than Ever Before!
            <a href="http://letsgolook.at/AIGames" target="_blank">
                <v-icon color="primary" x-small>arrow-right</v-icon>
                Claim Your $190 Discount Here
            </a>
        </div>
        <div
            v-if="isBetween(['2025-03-30 11:00'], ['2025-03-31 12:00'])"
            class="my-4"
        >
            [STARTING SOON!] The AI Games Start Monday @ 11am New York Time!
            Join Our 3-Day Challenge To 5X Yourself With A.I. And Grow ANY
            Business Faster Than Ever!
            <a href="http://letsgolook.at/AIGames" target="_blank">
                <v-icon color="primary" x-small>arrow-right</v-icon>
                Claim Your $190 Discount Here
            </a>
        </div>
        <div
            v-if="isBetween(['2025-03-31 11:00'], ['2025-04-07 12:00'])"
            class="my-4"
        >
            [NEW TRAINING] Tap Into The Hottest Niche Of 2025 & 10,000’s Of
            Desperate Buyers With Serious Budgets!
            <a href="https://letsgolook.at/DeFi2025" target="_blank">
                <v-icon color="primary" x-small>arrow-right</v-icon>
                RSVP Here
            </a>
        </div>
        <div
            v-if="isBetween(['2025-04-07 11:00'], ['2025-04-10 12:00'])"
            class="my-4"
        >
            [LAST CHANCE!] Closing Soon & DeFi Distribution “Single” Packs Now
            Open - Get Any Crypto, DeFi Or NFT Project Featured On 75+ Exclusive
            Google News Approved Crypto Media Sites
            <a href="https://letsgolook.at/DefiDistribution" target="_blank">
                <v-icon color="primary" x-small>arrow-right</v-icon>
                Get Your DeFi Discount Here
            </a>
        </div>
        <div
            v-if="isBetween(['2025-04-12 11:00'], ['2025-04-18 12:00'])"
            class="my-4"
        >
            Join The AI Games! Our 3-Day Challenge To 5X Yourself With A.I. In
            Your Business And Grow ANY Business Faster Than Ever Before!
            <a href="http://letsgolook.at/AIGames" target="_blank">
                <v-icon color="primary" x-small>arrow-right</v-icon>
                Claim Your $190 Discount Here
            </a>
        </div>
        <div
            v-if="isBetween(['2025-04-18 11:00'], ['2025-04-20 12:00'])"
            class="my-4"
        >
            [STARTING SOON!] The AI Games Start Sunday @ 11am New York Time!
            Join Our 3-Day Challenge To 5X Yourself With A.I. And Grow ANY
            Business Faster Than Ever!
            <a href="http://letsgolook.at/AIGames" target="_blank">
                <v-icon color="primary" x-small>arrow-right</v-icon>
                Claim Your $190 Discount Here
            </a>
        </div>

        <!-- Default Conditional Banners For Some Users -->

        <div v-if="isReseller && !isDropServeBuyer" class="my-4">
            Your Amp Sales Funnel has been updated to a simpler, more refined
            version that will help you to improve conversions.
            <br />
            To set this up and start moving leads forward,
            <a
                href="https://docs.google.com/document/d/1wslOSG9LE4mG3TfSx0TLGokuJmsnZhY0m5heSFBhHYQ/edit"
                target="_blank"
            >
                <v-icon color="primary" x-small>arrow-right</v-icon>
                please follow the steps listed here
            </a>
        </div>

        <div v-if="showPressCableUserWelcomeMessage" class="my-4">
            You have PressCable credits remaining. Upgrade to Amp Credits
            <router-link to="/credits/convert">
                Here
                <v-icon color="primary" x-small>arrow-right</v-icon>
            </router-link>
        </div>
    </a-banner>
</template>

<script lang="ts">
import Vue from 'vue';
import Component from 'vue-class-component';
import { mapGetters, mapState } from 'vuex';

import ABanner from '@/components/ABanner';

@Component({
    components: {
        ABanner
    },
    computed: {
        ...mapGetters('user', ['isReseller']),
        ...mapState('user', [
            'isLociCycleBuyer',
            'isAsigoBuyer',
            'isDropServeBuyer',
            'isBoringMethodBuyer',
            'isNewSimplerTrafficBuyer',
            'isPressCableUser',
            'hasPressCableCredits',
            'isKamManagedUser',
            'hasAiFeatures'
        ])
    }
})
export default class WelcomeBanner extends Vue {
    isReseller!: boolean;
    isLociCycleBuyer!: boolean;
    isAsigoBuyer!: boolean;
    isDropServeBuyer!: boolean;
    isBoringMethodBuyer!: boolean;
    isNewSimplerTrafficBuyer!: boolean;
    isPressCableUser!: boolean;
    hasPressCableCredits!: boolean;
    isKamManagedUser!: boolean;
    hasAiFeatures!: boolean;

    get timestamp() {
        return Math.round(new Date().getTime() / 1000);
    }

    get showPressCableUserWelcomeMessage() {
        return this.isPressCableUser && this.hasPressCableCredits;
    }

    get isValidAsigoBuyer() {
        return this.isAsigoBuyer || this.isDropServeBuyer;
    }

    get isAsigoBuyerOnly() {
        return this.isAsigoBuyer && !this.isDropServeBuyer;
    }

    isBetween(
        [fromDate, fromZone = 'America/New_York']: string[],
        [toDate, toZone = 'America/New_York']: string[]
    ) {
        const now = this.$date();

        return (
            now.isAfter(this.$date(fromDate).tz(fromZone, true)) &&
            now.isBefore(this.$date(toDate).tz(toZone, true))
        );
    }
}
</script>

<style lang="scss" scoped>
.welcome-banner::v-deep {
    &.ampcast {
        background-image: none;

        &:before {
            position: absolute;
            content: '';
            width: 100%;
            height: 100%;
            top: 0;
            left: 0;
            background-image: url('@/assets/img/ampcast-logo-black.svg');
            background-size: 11vw;
            background-position: calc(100% - 20px) 20px;
            opacity: 0.8;
            z-index: 1;
        }
    }

    div + div {
        &::before {
            content: '';
            display: block;
            position: relative;
            top: -8px;
            height: 0;
            width: 50%;
            border-top: 1px solid $secondary-color;
            opacity: 0.3;
        }
    }

    div:first-of-type {
        margin-top: 0 !important;
    }

    div:last-of-type {
        margin-bottom: 0 !important;
    }
}
</style>
