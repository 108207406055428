import { VAvatar } from 'vuetify/lib/components/VAvatar';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VHover } from 'vuetify/lib/components/VHover';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VImg } from 'vuetify/lib/components/VImg';
import { VRow } from 'vuetify/lib/components/VGrid';

var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c(VHover,{scopedSlots:_vm._u([{key:"default",fn:function({ hover }){return [_c(VRow,{staticClass:"amp-company-row ma-0 mb-2",class:{
            'concrete-solid': hover,
            'main-background': !hover,
            'elevation-2': hover || _vm.isActive,
            'px-2': _vm.$vuetify.breakpoint.mdAndDown
        },on:{"click":function($event){return _vm.edit(_vm.company)}}},[_c(VCol,{class:{ 'px-0': _vm.$vuetify.breakpoint.mdAndDown },attrs:{"cols":"1"}},[_c(VAvatar,{attrs:{"rounded":"","color":"white"}},[(_vm.logo)?_c(VImg,{attrs:{"max-width":"50","max-height":"50","src":_vm.logo}}):_c(VIcon,{attrs:{"size":"30","color":"grey lighten-2"}},[_vm._v(" image ")])],1)],1),_c(VCol,{staticClass:"pl-2 pr-0 ma-auto font-weight-medium",attrs:{"cols":"3"}},[_c('text-truncated',{attrs:{"text":_vm.company.name}})],1),_c(VCol,{staticClass:"pl-2 pr-0 ma-auto",attrs:{"cols":"3"}},[_c('text-truncated',{attrs:{"text":_vm.company.contact_name}})],1),_c(VCol,{staticClass:"pl-2 pr-0 ma-auto",attrs:{"cols":"4"}},[_c('text-truncated',{attrs:{"text":_vm.company.full_address}})],1),_c(VCol,{staticClass:"pl-2 pr-0 py-0 ma-auto",attrs:{"cols":"1","align":"right"}},[_c('company-row-actions',{key:_vm.company.id,attrs:{"company":_vm.company},on:{"open":_vm.markRowActive,"edit":_vm.edit,"drop":_vm.drop}})],1)],1)]}}])})
}
var staticRenderFns = []

export { render, staticRenderFns }